<template>
  <history-stack
    :key="objectId"
    :default-view-component="() => import('./ObjectCard')"
    v-bind="$attrs"
    @stack:close="handleClose"
  />
</template>

<script>
import { computed } from '@vue/composition-api';

export default {
  name: 'ObjectHistoryStack',
  setup(props, { root }) {
    const handleClose = () => {
      const { objectId, ...params } = root.$route.params;
      const { st, ...query } = root.$route.query;
      root.$router.push({
        name: 'objects',
        params,
        query
      });
    };
    return {
      handleClose,
      objectId: computed(() => root.$route.params.objectId)
    };
  }
};
</script>
